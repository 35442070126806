import React, { FC } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

import Button from 'gatsby-theme-husky/src/common/Button';
import { IPropsNavItem } from 'gatsby-theme-husky/src/components/Header/HeaderNavigation/models';
import { DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';
import { multiUrlPickerUtils } from 'gatsby-theme-husky/src/utils';

import './NavItemOverride.scss';

const NavItem: FC<IPropsNavItem> = ({
  navItem: {
    properties: { navigationWithSubMenu, url, label },
  },
  activeMainCategory,
  handleNavigationItemClick,
  handleActiveListPage,
}) => {
  const TopNavItem: FC = ({ children }) => {
    const topNavItemUrl = multiUrlPickerUtils.extractUrl(url);

    return (
      <>
        {topNavItemUrl ? (
          <Link className="main-nav-link" to={topNavItemUrl}>
            {children}
          </Link>
        ) : (
          children
        )}
      </>
    );
  };

  return (
    <li
      className={classNames(`main-navigation-item`, {
        'main-navigation-item-with-sub': navigationWithSubMenu,
        active: activeMainCategory && activeMainCategory === label,
      })}
      onMouseEnter={navigationWithSubMenu ? handleNavigationItemClick(label) : undefined}
      onMouseLeave={handleNavigationItemClick(null)}
    >
      <TopNavItem>
        <Button
          classes={classNames(
            { 'main-nav-link nav-link-with-sub': navigationWithSubMenu },
            { 'main-nav-link': !navigationWithSubMenu }
          )}
          handler={() => (handleActiveListPage ? handleActiveListPage(0) : null)}
        >
          <DangerouslySetInnerHtml html={label} />
        </Button>
      </TopNavItem>
      {navigationWithSubMenu ? (
        <ul className="secondary-nav">
          {navigationWithSubMenu.map((item) => (
            <li className="secondary-nav__item" key={item.properties.label}>
              <Link
                onClick={handleNavigationItemClick(null)}
                className="main-nav-link secondary-nav__link"
                to={multiUrlPickerUtils.extractUrl(item.properties.url)}
              >
                <DangerouslySetInnerHtml html={item.properties.label} />
              </Link>
            </li>
          ))}
        </ul>
      ) : null}
    </li>
  );
};

export default NavItem;
