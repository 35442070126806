import React, { FC, useState, useCallback } from 'react';
import { Link } from 'gatsby';
import Button from 'gatsby-theme-husky/src/common/Button';
import { NavProps } from 'gatsby-theme-husky/src/components/Header/MobileHeaderNavigation/models';
import { DangerouslySetInnerHtml } from 'gatsby-theme-husky/src/layout';

import 'gatsby-theme-husky/src/components/Header/MobileHeaderNavigation/MobileHeaderNavigation.scss';
import './MobileHeaderNavigationOverride.scss';

const MobileHeaderNavigation: FC<NavProps> = ({
  allNodeNavigation: { nodes },
  navigationTexts: { backToMainMenuAriaLabel, backToMainMenuText, openSubMenuAriaLabel },
  toggleMobileMenu,
}) => {
  const [activeSubMenu, setActiveSubMenu] = useState<any | null>(null);
  const setActiveSubMenuHandler = useCallback(
    (menu: any) => {
      setActiveSubMenu(menu);
    },
    [activeSubMenu]
  );

  return (
    <div className="mobile-navigation">
      <ul className="mobile-navigation__ul">
        {!activeSubMenu &&
          nodes.map((link) => {
            const { label, navigationWithSubMenu, url } = link.properties;
            const { properties } = link;
            if (navigationWithSubMenu) {
              return (
                <li
                  key={`${label}-mobile-navigation-with-submenu`}
                  className="mobile-navigation__li"
                >
                  <Button
                    className="arrow__icon"
                    ariaLabel={openSubMenuAriaLabel}
                    handler={() => setActiveSubMenuHandler(properties)}
                    type="button"
                    btnColorVariant="white-thin"
                  >
                    <DangerouslySetInnerHtml html={label} />
                  </Button>
                </li>
              );
            }

            return (
              <Link
                className="mobile-navigation-link"
                to={url[0].url}
                key={`${label}-mobile-navigation`}
                activeClassName="active"
                onClick={() => toggleMobileMenu()}
              >
                <li className="mobile-navigation__li">
                  <DangerouslySetInnerHtml html={label} />
                </li>
              </Link>
            );
          })}
      </ul>
      {activeSubMenu && (
        <ul className="mobile-navigation__ul">
          <li className="mobile-navigation__li navigation-back">
            <Button
              className="arrow-back__icon"
              aria-label={backToMainMenuAriaLabel}
              handler={() => setActiveSubMenuHandler(null)}
              type="button"
              btnColorVariant="white-thin"
            >
              {backToMainMenuText}
            </Button>
          </li>
          <Link
            key={activeSubMenu.label}
            to={activeSubMenu.url[0]?.url}
            activeClassName="active"
            onClick={() => toggleMobileMenu()}
          >
            <li className="mobile-navigation__li">
              <DangerouslySetInnerHtml html={activeSubMenu.label} />
            </li>
          </Link>
          {activeSubMenu.navigationWithSubMenu.map((subLink: { properties: { label; url } }) => {
            const { label, url } = subLink.properties;

            return (
              <Link
                key={`${label}-mobile-navigation-with-submenu-active`}
                to={url[0].url}
                activeClassName="active"
                onClick={() => toggleMobileMenu()}
              >
                <li className="mobile-navigation__li">
                  <DangerouslySetInnerHtml html={label} />
                </li>
              </Link>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default MobileHeaderNavigation;
