import React, { FC, useRef } from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames';

import { useAllSitePageContext } from 'context/allSitePage';
import useClickOutside from 'hooks/useClickOutside';

import { HeaderLanguageSelectorProps } from './model';
import './HeaderLanguageSelector.scss';

const HeaderLanguageSelector: FC<HeaderLanguageSelectorProps> = ({
  languages,
  isActive,
  onClick,
  languageLetters,
}) => {
  const { getPageUrlByLang } = useAllSitePageContext();

  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, () => {
    if (isActive) {
      onClick();
    }
  });

  const links = languages.reduce((acc: { url: string; langName: string }[], lang) => {
    if (typeof document === `undefined`) return acc;

    const { pathname, search } = document.location;

    const langPrefix = lang === '/' ? '/en' : lang;
    const langName = langPrefix.replace(/\//g, '');

    const isSelectedLang = langName.toLowerCase() === languageLetters[0].toLowerCase();

    if (!isSelectedLang) {
      acc.push({
        url: getPageUrlByLang(pathname, langName) + search,
        langName: langName.toUpperCase(),
      });
    }

    return acc;
  }, []);

  const changeOTLang = (langName: string): void => window.OneTrust?.changeLanguage(langName);

  return (
    <button
      onClick={onClick}
      className={classnames('language-selector', { 'active-drop': isActive })}
      aria-label={languageLetters[0]}
      ref={wrapperRef}
      type="button"
    >
      <span className="language-selector__opener">{languageLetters[0]}</span>
      {links ? (
        <div className="language-selector__slide">
          <ul className="language-selector__list">
            {links.map(({ url, langName }) => (
              <li key={url}>
                <Link
                  className="language-selector__link"
                  to={url}
                  onClick={() => changeOTLang(langName)}
                >
                  {langName}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </button>
  );
};

export default HeaderLanguageSelector;
