import React, { FC } from 'react';
import Container from 'layout/Container';
import TopFooter from './TopFooter';
import MiddleFooter from './MiddleFooter';
import BottomFooter from './BottomFooter';
import { IFooterProps } from './model';
import './Footer.scss';
import './FooterRTL.scss';

const Footer: FC<IFooterProps> = ({
  bottomLinks,
  copyrightBlock,
  navigation,
  mediaLinks,
  hideMediaLinks,
  dataLogo,
}) => (
  <footer className="footer">
    <Container className="footer-container">
      <TopFooter
        dataLogo={dataLogo}
        dataMediaLinks={mediaLinks}
        dataFooterHideMediaLinks={hideMediaLinks}
      />
      <MiddleFooter dataNavigation={navigation} dataLinks={bottomLinks} />
      <BottomFooter dataCopyright={copyrightBlock} />
    </Container>
  </footer>
);

export default Footer;
